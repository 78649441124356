import { graphql, useStaticQuery } from "gatsby";
export const useCaseOverview = () => {
	const data = useStaticQuery(graphql`
		query CaseOverviewQuery {
			directus {
				cases(
					filter: {
						favorite: { _eq: true }
						status: { _eq: "published" }
					}
				) {
					id
					slug
					title
					cover {
						id
						imageFile {
							id
							childImageSharp {
								gatsbyImageData(
									width: 800
									quality: 75
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
	`);

	return data.directus.cases;
};
