import React from "react";
import classnames from "classnames";

const SubHeading = ({ children, className, ...rest }) => {
	const classes = classnames(
		className,
		"text-muted text-base md:text-lg font-normal"
	);

	return (
		<h2 className={classes} {...rest}>
			{children}
		</h2>
	);
};

export default SubHeading;
