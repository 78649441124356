import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { getCaseURL } from "../utils/get-asset-url";

const CaseItem = ({ slug, title, image }) => {
	return (
		<div className="group object-cover h-full w-full">
			<Link to={getCaseURL(slug)}>
				<GatsbyImage
					image={getImage(image.imageFile)}
					className="object-cover h-full w-full"
					imgClassName="rounded-spark"
					alt={title}
				/>

				<div className="absolute bottom-4 w-full flex align-middle justify-center">
					<span className="bg-black/75 text-white px-4 py-2 mx-2 rounded-full text-sm opacity-0 group-hover:opacity-100 transition-all group-hover:translate-y-0 translate-y-4">
						<span className="text-muted mr-3">Case</span>
						{title}
					</span>
				</div>
			</Link>
		</div>
	);
};

export default CaseItem;
