import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowButton from "./arrow-button";
import Button from "./button";
import { Autoplay } from "swiper";
import classNames from "classnames";
import CaseItem from "./case-item";

import "swiper/css";
import "swiper/css/navigation";

const CaseCarousel = ({ cases, darkBg = false }) => {
	const [swiper, setSwiper] = React.useState(null);

	const nexto = () => {
		swiper.slideNext();
	};

	const prevto = () => {
		swiper.slidePrev();
	};

	const colorClassName = darkBg ? "bg-gray" : "bg-off-white";
	const containerClassName = classNames(
		"h-[50vh] md:h-[70vh] md:min-h-[700px]",
		colorClassName
	);

	return (
		<>
			<Swiper
				modules={[Autoplay]}
				autoplay
				className={containerClassName}
				loop={true}
				slidesPerView={1}
				slidesPerGroupSkip={2}
				centeredSlides={true}
				spaceBetween={2}
				breakpoints={{
					900: {
						slidesPerView: 2,
					},
				}}
				onSwiper={(s) => {
					setSwiper(s);
				}}
			>
				{cases.map(
					(caseItem, i) =>
						caseItem.cover && (
							<SwiperSlide key={caseItem.id}>
								<CaseItem
									slug={caseItem.slug}
									title={caseItem.title}
									image={caseItem.cover}
								/>
							</SwiperSlide>
						)
				)}

				<ArrowButton
					onClick={prevto}
					className="absolute bottom-0 m-4 z-50"
					iconClassName="rotate-180"
				/>
				<ArrowButton
					onClick={nexto}
					className="absolute bottom-0 right-0 m-4 z-50"
				/>
			</Swiper>

			<div className={classNames(colorClassName, "text-right p-4")}>
				<Button
					to="/portfolio"
					dark={!darkBg}
					iconClassName="-rotate-45"
				>
					Bekijk ons portfolio
				</Button>
			</div>
		</>
	);
};

export default CaseCarousel;
