import React from "react";
import Heading from "./heading";
import Button from "./button";
import ArrowButton from "./arrow-button";
import SubHeading from "./subheading";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ExtendedTitle = ({
	children,
	subtitle,
	image,
	backButtonTitle,
	backButtonTo,
}) => {
	return (
		<section className="bg-gray relative md:flex md:divide-x divide-gray">
			<div className="w-[50px] flex-initial h-auto rounded-spark-r bg-off-white"></div>
			<div className="bg-off-white w-full rounded-spark">
				<div className="lg:flex">
					<div className="flex items-center justify-center flex-col px-36">
						{backButtonTo && backButtonTitle && (
							<Button
								dark={true}
								to={backButtonTo}
								className="hidden lg:block mb-24"
							>
								{backButtonTitle}
							</Button>
						)}

						<ArrowButton
							dark={true}
							className="hidden lg:block h-18 w-18"
							iconClassName="rotate-90 h-18 w-18"
						></ArrowButton>
					</div>
					<div className="py-24 lg:py-48 px-12">
						{subtitle && <SubHeading>{subtitle}</SubHeading>}
						<Heading size="text-5xl md:text-6xl lg:text-8xl">
							{children}
						</Heading>
					</div>
				</div>

				{image && (
					<div className="h-[60vh] min-h-[300px] w-full">
						<GatsbyImage
							imgClassName="rounded-spark"
							className="object-cover h-full w-full rounded-spark"
							alt={children}
							image={getImage(image.imageFile)}
							loading="eager"
						/>
					</div>
				)}
			</div>
		</section>
	);
};

export default ExtendedTitle;
